import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addProducts } from '../../redux/cartRedux'

const SpecialItem = ({item}) => {


    const dispatch = useDispatch()
    const quantity = 1

    const cartDetails = useSelector(state => state.cart)

    const handleClick =()=> {


        const targetId = item._id; // Replace with the ID you want to check
        let idExists = false;
        
        for (let i = 0; i < cartDetails.products.length; i++) {
          if (cartDetails.products[i]._id === targetId) {
            idExists = true;

           
            break;
          }
        }

        if (!idExists) {
            dispatch(
                addProducts({...item, quantity})
            )
        }
        

       
    }
  return (
    <li className="product-item">
                <div className="product-card" tabIndex={"0"}>

                  <figure className="card-banner">
                    <img src={item.img} width="312" height="350" loading="lazy"
                      alt="Running Sneaker Shoes" className="image-contain"/>

                    <div className="card-badge">New</div>

                    <ul className="card-action-list">

                      <li className="card-action-item">
                        <button onClick={handleClick} className="card-action-btn" aria-labelledby="card-label-1">
                          <ion-icon name="cart-outline"></ion-icon>
                        </button>

                        <div className="card-action-tooltip" id="card-label-1">Add to Cart</div>
                      </li>

                      <li className="card-action-item">
                        <button className="card-action-btn" aria-labelledby="card-label-2">
                          <ion-icon name="heart-outline"></ion-icon>
                        </button>

                        <div className="card-action-tooltip" id="card-label-2">Add to Whishlist</div>
                      </li>

                      <li className="card-action-item">
                        <a href={`/product/${item._id}`} className="card-action-btn" aria-labelledby="card-label-3">
                          <ion-icon name="eye-outline"></ion-icon>
                        </a>

                        <div className="card-action-tooltip" id="card-label-3">Quick View</div>
                      </li>

                      <li className="card-action-item">
                        <button className="card-action-btn" aria-labelledby="card-label-4">
                          <ion-icon name="repeat-outline"></ion-icon>
                        </button>

                        <div className="card-action-tooltip" id="card-label-4">Compare</div>
                      </li>

                    </ul>
                  </figure>

                  <div className="card-content">

                    <div className="card-cat">
                   
                        {item.category.toString().replaceAll(",", "/")}
                  
                    </div>

                    <h3 className="h3 card-title">
                      <a href={`/product/${item._id}`}>{item.title}</a>
                    </h3>

                    <data className="card-price" value="180.85">&#8358;{item.price}</data>

                  </div>

                </div>
              </li>
  )
}

export default SpecialItem