import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import Logo from '../logo/Logo';
import Cart from '../Cart/Cart';

const Header = () => {

  const cart = useSelector((state) => state.cart)
  const quantity = cart.products.length
  const [showCart, setShowCart] = useState(false)

  // console.log(cart)
  
  useEffect(() => {
    const overlay = document.querySelector("[data-overlay]");
    const navOpenBtn = document.querySelector("[data-nav-open-btn]");
    const navbar = document.querySelector("[data-navbar]");
    const navCloseBtn = document.querySelector("[data-nav-close-btn]");
    
    
    
    const handleToggle = () => {
      navbar.classList.toggle("active");
      overlay.classList.toggle("active");
    };
    
    const navElems = [overlay, navOpenBtn, navCloseBtn];
    
    for (let i = 0; i < navElems.length; i++) {
      navElems[i]?.addEventListener("click", handleToggle);
    }
    
    
    // Clean up by removing the event listeners when the component unmounts
    return () => {
      for (let i = 0; i < navElems.length; i++) {
        navElems[i]?.removeEventListener("click", handleToggle);
      }
      
    };
    
  }, []); // Empty dependency array ensures the useEffect only runs once when the component mounts
  


  const styles = {
    popup: {
      position: "absolute",
      right: showCart? "0px": "-100%",
      top: showCart? "0px" : "50px"
    }
  }

  return (
    <header className="header" data-header>
    <div className="container">

      <div className='cart-toggle' style={styles.popup}>
        {showCart && <Cart setShowCart={setShowCart} />}
      </div>

      <div className="overlay" data-overlay></div> 

      <a href="/" className="logo"> 
        <img src="/LUJ-black.svg" width="50" height="45" alt="Footcap logo"/>
        {/* <Logo /> */}
      </a>


      <div  style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
        <div className="nav-open-btn" style={{position: 'relative'}}>
          <button onClick={()=> setShowCart(!showCart)}  className="nav-action-btn-cart">
            <ion-icon name="bag-outline" ></ion-icon>
            <p style={{position: 'absolute', top: '3px', right: '3px', color: 'white', fontSize: '20px', fontWeight: '400'}}>
              {quantity}
            </p>
            {/* <data className="nav-action-badge" value={quantity}>{quantity}</data> */}
          </button>
        </div>
        <button className="nav-open-btn" data-nav-open-btn aria-label="Open Menu">
          <ion-icon name="menu-outline"></ion-icon>
        </button>
      </div>


  

      <nav className="navbar" data-navbar>

        <button className="nav-close-btn" data-nav-close-btn aria-label="Close Menu">
          <ion-icon name="close-outline"></ion-icon>
        </button>

        <a href="/" className="logo">
          {/* <img src="./assets/images/logo.svg" width="190" height="50" alt="Footcap logo"/> */}
          <Logo />
        </a>

        <ul className="navbar-list">

          <li className="navbar-item">
            <a href="/" className="navbar-link">Home</a>
          </li>

          <li className="navbar-item">
            <a href="/" className="navbar-link">Products</a>
          </li>

          <li className="navbar-item">
            <a href="/contact" className="navbar-link">Contact</a>
          </li>

        </ul>

        <ul className="nav-action-list">

          <li>
            <button className="nav-action-btn">
              <ion-icon name="search-outline" aria-hidden="true"></ion-icon>

              <span className="nav-action-text">Search</span>
            </button>
          </li>

          <li>
            <a href="/login" className="nav-action-btn">
              <ion-icon name="person-outline" aria-hidden="true"></ion-icon>

              <span className="nav-action-text">Login / Register</span>
            </a>
          </li>

          <li>
            <button className="nav-action-btn">
              <ion-icon name="heart-outline" aria-hidden="true"></ion-icon>

              <span className="nav-action-text">Wishlist</span>

              <data className="nav-action-badge" value="5" aria-hidden="true">5</data>
            </button>
          </li>

          <li>
            <button onClick={()=> setShowCart(!showCart)} className="nav-action-btn">
              <ion-icon name="bag-outline" aria-hidden="true"></ion-icon>

              <data className="nav-action-text" value="318.00">Basket: <strong>$318.00</strong></data>

              <data className="nav-action-badge" value={quantity} aria-hidden="true">{quantity}</data>
            </button>
          </li>

        </ul>

      </nav>

    </div>
  </header>
  )
}

export default Header