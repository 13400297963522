import { Clear } from '@mui/icons-material'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3"
import { useNavigate } from 'react-router-dom'
import {removeProducts, resetCart} from '../../redux/cartRedux'


const Cart = ({setShowCart}) => {


    const dispatch = useDispatch()
    // const quantity = 1

    const navigate = useNavigate()
    const [name, setName] = useState('')
    const [phone_number, setPhone] = useState('')
    const [email, setEmail] = useState('')
    
    
    const cartDetails = useSelector(state => state.cart)
    
    
    const handleClick =(item)=> {
        dispatch(
            removeProducts(item)
        )
    }

    const resetCart1 =() => {
        dispatch(resetCart())
    }

    const products = cartDetails.products

    const totalPrice = products.reduce((acc, product) => {
        return acc + product.price * product.quantity
    }, 0)


// handle flutterwave payment

const config = {
    public_key: "FLWPUBK_TEST-da8831bd6bb8af3cc7d5d27e624488c5-X",
    tx_ref: Date.now(),
    amount: totalPrice,
    currency: "NGN",
    meta: {
        cartObject: {cartDetails}
    },
    payment_options: "card,mobilemoney,ussd",
    customer: {
      email: email,
      phone_number: phone_number,
      name: name,
    },
    customizations: {
      title: "LUJ Checkout",
      description: "Payment for items in cart",
      logo: "https://luj-store.vercel.app/LUJ-black.png"
    //   logo: "https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg",
    },
}


const handleFlutterPayment = useFlutterwave(config)


  return (

    <div className=''>

    
    <div className={` cart-container animate-fade  float-right p-5 h-[80vh] bg-zinc-50 w-full md:w-full`}>
        <div onClick={()=> setShowCart(false)} 
                style={{position: 'absolute', top: '20px', right: '20px', cursor: 'pointer'}}
                className=' fixed top-5 right-5 cursor-pointer'>
            <Clear color='' fontSize='large' />
        </div>
        <p style={{fontSize: '1.5rem', marginTop: '20px', marginBottom: '20px'}} className=' text-sm mt-5 font-semibold mb-5'>
            Shopping Cart
        </p>
        { 
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', overflowX: 'hidden', maxHeight: '70%', padding: '0 20px'}} className=' cart-style flex flex-col items-center overflow-x-hidden max-h-[50%]'>
            

            {cartDetails.products.map(item => (
            

                <div item={item} key={item._id} style={{display: 'flex', justifyContent: 'space-between', width: '100%', padding: '16px', marginTop: '20px', borderBottom: '1px solid gray', borderRadius: '8px'}} className=' flex p-4 mt-5 border-b border-blue-950/20 rounded-lg'>
                    <div style={{width: '60px', height: '60px', borderRadius: '7px', overflow: 'hidden', display: 'flex', backgroundColor: '#fff'}} className='w-1/4 h-full p-2 overflow-hidden flex '>
                        <img style={{objectFit: "cover", width: '100%', height: '100%'}} className='' src={item.img} alt="" />
                    </div>
                    <div style={{width: '50%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', padding: '5px' }} className="w-1/2 py-1 px-2 flex flex-col justify-between">
                        <p style={{fontSize: '1.2rem', marginBottom: '4px', textTransform: 'capitalize', fontWeight: '200'}} className=" text-[0.6rem] mb-1 capitalize font-light">{item.title.slice(0, 20)}...</p>
                        <p style={{fontSize: '0.7rem', marginBottom: '4px', textTransform: 'uppercase', fontWeight: '400'}} className=" text-[0.6rem] uppercase font-semibold">{item.brand}</p>
                        
                    </div>
                    <div style={{width: '25%', padding: '4px 0', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', textAlign: 'right'}} className='w-1/4 py-1 flex flex-col justify-between text-right'>
                        <p style={{fontWeight: '100'}} className=' font-extralight'>&#8358;{item.price}</p>
                        <p onClick={()=>handleClick(item)} style={{fontSize: '1.0rem', cursor: 'pointer', backgroundColor: 'black', color: 'white', textAlign: 'center', padding: '3px 5px', borderRadius: '6px'}} className=' uppercase text-xs'>remove</p>
                    </div>
                </div>

            ))}


        </div>}


        { cartDetails.products.length <1 ? 
        
            <div style={{ fontSize: '20px', textAlign: 'center', marginTop: '30px', fontWeight: '200'}}>
                You have no items in your basket
            </div>
        
        :

        <div style={{marginTop: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center'}} className=' w-full flex mt-8 flex-col items-center'>
            <p style={{ display: 'flex', fontSize: '14px', fontWeight: '600'}} className=' text-sm font-semibold'>Total: 
                <span style={{fontWeight: '300'}} className=' font-light'>
                    &nbsp;&#8358;{totalPrice}
                </span></p>
            {/* <p style={{fontSize: '1rem', marginTop: '10px'}} className="text-xs text-center mt-5">
                Sign up for 10% off your first order
            </p> */}

            <div style={{padding: '0 50px', marginTop: '20px'}}>
                <input type="text" className='checkout-form' placeholder='Name' onChange={(e)=> setName(e.target.value) } />
                <input type="text" className='checkout-form' placeholder='E-mail' onChange={(e)=> setEmail(e.target.value)} />
                <input type="text" className='checkout-form' placeholder='Phone' onChange={(e)=> setPhone(e.target.value)} />
            </div>

            <button  
                onClick={()=> {
                    handleFlutterPayment({
                        callback: (res)=> {
                            console.log(res)
                            if(res.status === "successful"){
                                resetCart1()
                                navigate("/success", {
                                    state: {
                                        flwData: res,
                                        products: cartDetails,
                                        phone_number
                                    }
                                } ) 
                            }
                            closePaymentModal()
                        }
                    })
                }}
                style={{fontSize: '10px', boxShadow: '0 3px 7px rgb(0 0 0/0.6)', textTransform: 'uppercase', marginTop: '20px', padding: '8px 20px', color: 'white', backgroundColor: 'black'}}
                className=' text-[0.6rem] shadow-md uppercase bg-transparent mt-5 py-1 px-5 transform hover:text-white hover:bg-yellow-800/70 ease-in duration-500 hover:scale-110'>
                continue to checkout
            </button>
        </div>
        }
    
</div>


    </div>
  )
}

export default Cart