import React, { useEffect, useState } from 'react'
import { publicRequest } from '../../requestMethods'
import SpecialItem from '../specialItem/specialItem'

const Special = () => {


  const [featured, setFeatured] = useState([])

  useEffect(()=>{
    const getFeatured = async()=> {
      try {
        const res = await publicRequest.get(`/product`)
        setFeatured(res.data)
      } catch (error) {
        
      }
    }
    getFeatured()
  }, [])

  const displayedFeatured = featured.filter(x => x.brand === "Givenchy")

  return (
    <section className="section special">
        <div className="container">

          <div className="special-banner" style={{backgroundImage: "url('./assets/images/special-banner.jpg')"}}>
            <h2 className="h3 banner-title">New Trend Edition</h2>

            <a href="/" className="btn btn-link">
              <span>Explore All</span>

              <ion-icon name="arrow-forward-outline" aria-hidden="true"></ion-icon>
            </a>
          </div>

          <div className="special-product">

            <h2 className="h2 section-title">
              <span className="text">Givenchy Special</span>

              <span className="line"></span>
            </h2>

            <ul className="has-scrollbar">

              {displayedFeatured?.map(item => (

                
                <SpecialItem key={item._id} item={item} />

                ))}


              {/* <li class="product-item">
                <div class="product-card" tabIndex={"0"}>

                  <figure class="card-banner">
                    <img src="./assets/images/product-2.jpg" width="312" height="350" loading="lazy"
                      alt="Leather Mens Slipper" class="image-contain"/>

                    <ul class="card-action-list">

                      <li class="card-action-item">
                        <button class="card-action-btn" aria-labelledby="card-label-1">
                          <ion-icon name="cart-outline"></ion-icon>
                        </button>

                        <div class="card-action-tooltip" id="card-label-1">Add to Cart</div>
                      </li>

                      <li class="card-action-item">
                        <button class="card-action-btn" aria-labelledby="card-label-2">
                          <ion-icon name="heart-outline"></ion-icon>
                        </button>

                        <div class="card-action-tooltip" id="card-label-2">Add to Whishlist</div>
                      </li>

                      <li class="card-action-item">
                        <button class="card-action-btn" aria-labelledby="card-label-3">
                          <ion-icon name="eye-outline"></ion-icon>
                        </button>

                        <div class="card-action-tooltip" id="card-label-3">Quick View</div>
                      </li>

                      <li class="card-action-item">
                        <button class="card-action-btn" aria-labelledby="card-label-4">
                          <ion-icon name="repeat-outline"></ion-icon>
                        </button>

                        <div class="card-action-tooltip" id="card-label-4">Compare</div>
                      </li>

                    </ul>
                  </figure>

                  <div class="card-content">

                    <div class="card-cat">
                      <a href="#" class="card-cat-link">Men</a> /
                      <a href="#" class="card-cat-link">Sports</a>
                    </div>

                    <h3 class="h3 card-title">
                      <a href="#">Leather Mens Slipper</a>
                    </h3>

                    <data class="card-price" value="190.85">$190.85</data>

                  </div>

                </div>
              </li> */}

              {/* <li class="product-item">
                <div class="product-card" tabIndex={"0"}>

                  <figure class="card-banner">
                    <img src="./assets/images/product-3.jpg" width="312" height="350" loading="lazy"
                      alt="Simple Fabric Shoe" class="image-contain"/>

                    <div class="card-badge">New</div>

                    <ul class="card-action-list">

                      <li class="card-action-item">
                        <button class="card-action-btn" aria-labelledby="card-label-1">
                          <ion-icon name="cart-outline"></ion-icon>
                        </button>

                        <div class="card-action-tooltip" id="card-label-1">Add to Cart</div>
                      </li>

                      <li class="card-action-item">
                        <button class="card-action-btn" aria-labelledby="card-label-2">
                          <ion-icon name="heart-outline"></ion-icon>
                        </button>

                        <div class="card-action-tooltip" id="card-label-2">Add to Whishlist</div>
                      </li>

                      <li class="card-action-item">
                        <button class="card-action-btn" aria-labelledby="card-label-3">
                          <ion-icon name="eye-outline"></ion-icon>
                        </button>

                        <div class="card-action-tooltip" id="card-label-3">Quick View</div>
                      </li>

                      <li class="card-action-item">
                        <button class="card-action-btn" aria-labelledby="card-label-4">
                          <ion-icon name="repeat-outline"></ion-icon>
                        </button>

                        <div class="card-action-tooltip" id="card-label-4">Compare</div>
                      </li>

                    </ul>
                  </figure>

                  <div class="card-content">

                    <div class="card-cat">
                      <a href="#" class="card-cat-link">Men</a> /
                      <a href="#" class="card-cat-link">Women</a>
                    </div>

                    <h3 class="h3 card-title">
                      <a href="#">Simple Fabric Shoe</a>
                    </h3>

                    <data class="card-price" value="160.85">$160.85</data>

                  </div>

                </div>
              </li> */}

              {/* <li class="product-item">
                <div class="product-card" tabIndex={"0"}>

                  <figure class="card-banner">
                    <img src="./assets/images/product-4.jpg" width="312" height="350" loading="lazy"
                      alt="Air Jordan 7 Retro " class="image-contain"/>

                    <div class="card-badge"> -25%</div>

                    <ul class="card-action-list">

                      <li class="card-action-item">
                        <button class="card-action-btn" aria-labelledby="card-label-1">
                          <ion-icon name="cart-outline"></ion-icon>
                        </button>

                        <div class="card-action-tooltip" id="card-label-1">Add to Cart</div>
                      </li>

                      <li class="card-action-item">
                        <button class="card-action-btn" aria-labelledby="card-label-2">
                          <ion-icon name="heart-outline"></ion-icon>
                        </button>

                        <div class="card-action-tooltip" id="card-label-2">Add to Whishlist</div>
                      </li>

                      <li class="card-action-item">
                        <button class="card-action-btn" aria-labelledby="card-label-3">
                          <ion-icon name="eye-outline"></ion-icon>
                        </button>

                        <div class="card-action-tooltip" id="card-label-3">Quick View</div>
                      </li>

                      <li class="card-action-item">
                        <button class="card-action-btn" aria-labelledby="card-label-4">
                          <ion-icon name="repeat-outline"></ion-icon>
                        </button>

                        <div class="card-action-tooltip" id="card-label-4">Compare</div>
                      </li>

                    </ul>
                  </figure>

                  <div class="card-content">

                    <div class="card-cat">
                      <a href="#" class="card-cat-link">Men</a> /
                      <a href="#" class="card-cat-link">Sports</a>
                    </div>

                    <h3 class="h3 card-title">
                      <a href="#">Air Jordan 7 Retro </a>
                    </h3>

                    <data class="card-price" value="170.85">$170.85 <del>$200.21</del></data>

                  </div>

                </div>
              </li> */}

            </ul>

          </div>

        </div>
      </section>
  )
}

export default Special