import { Google } from '@mui/icons-material'
import React from 'react'
import Header from '../components/header/header'
// import background from './../1.jpg'

const Register = () => {
  return (
    <>
    <Header />
    <div className='login-container h-screen flex bg-stone-200 '>
        <div className=' login-left h-screen w-full md:w-1/2 flex flex-col'>
            {/* <div className=' flex p-10'>
                <p className='text-3xl font-bold uppercase tracking-tighter text-gray-700'>
                    LUJ
                </p>
            </div> */}
            <div className=' items-center w-full flex p-12 md:p-20 justify-center h-full'>
                <div className=' flex flex-grow flex-col items-center'>
                    <p style={{marginBottom: '20px'}} className=' text-lg font-extralight'>
                        Sign Up
                    </p>
                    <input type="text" placeholder='Full Name' className='register-form' />
                    <input type="email" placeholder='Email' className='register-form' />
                    <input type="password" placeholder='Password' className='register-form' />
                    <input type="password" placeholder='Confirm Password' className='register-form' />
                    <button className='reg-btn'
                            style={{fontSize: "1.5rem", border: "1px gray solid", margin: "12px", padding: "8px 0", width: "100%", display: "flex", justifyContent: "center"}}
                                                >
                        Signup
                    </button>
                    <button className='reg-btn'
                            style={{fontSize: "1.2rem", border: "1px gray solid", margin: "12px", padding: "8px 0", width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}
                                                        >
                        <Google />Continue with Google
                    </button>
                    <h6 className=' text-[0.7rem] text-gray-600'>
                        Already have an account? <span className=' underline text-yellow-700 cursor-pointer '>Login</span>
                    </h6>
                </div>
            </div>
        </div>
        <div className={`login-right hidden md:w-1/2 h-screen bg-reg-pattern bg-cover md:flex`}>
        
        </div>
    </div>
    
    </>
  )
}

export default Register