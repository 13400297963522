import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { userRequest } from '../requestMethods'
import Header from '../components/header/header'

const Success = () => {

  const location = useLocation()
  const phone = location.state.phone_number
  const data = location.state.flwData
  const cart = location.state.products
  const [orderId, setOrderId] = useState(null)


  const navigate = useNavigate()



  useEffect(()=> {

    const addOrder = async ()=>{
        try {
            const res = await userRequest.post("/order/", {
                userId: "0000",
                products: cart.products.map((item)=> ({
                  productId: item._id,
                  quantity: item.quantity
                })),
                amount: data.amount,
                meta: data,
                address: phone
            })
            setOrderId(res.data._id)
        } catch{}
    }
    data && addOrder()

}, [cart, data, phone])

// function confirmExit() {
//   alert("exiting to home");
//   window.location.href="/";
//   navigate('/')
//   return true
// }

// window.onbeforeunload = confirmExit
    
  return (
   
      <>
      <Header />
        <div 
          style={{ display: 'flex', height: '80vh', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}
          className="flex h-screen flex-col justify-center items-center bg-gray-50">
          <p 
            style={{fontSize: '36px', textTransform: 'capitalize', fontWeight: '700', marginBottom: '25px', color: 'green'}}
            className=' text-4xl capitalize font-bold text-green-700 mb-6'>
            order confirmed!
          </p>
          <div>

          {
            orderId? <div style={{textAlign: 'center'}} className=' text-center'>
              <p style={{fontSize: '20px'}} className=' text-xl'>Your order is successfully created. your order id is <br /> 
              <span style={{fontWeight: '700', color: 'green'}} className=' font-bold text-green-900'>
              {orderId}
                </span></p>
            </div> :
            `Payment seccessful! your order is being prepared...`
          }
          </div>
          <button 
            className= 'bg-black text-white px-10 py-2 rounded-md mt-5'
            onClick={()=>navigate('/')}
            style={{backgroundColor: 'black', color: 'white', padding: '10px 40px', borderRadius: '7px', marginTop: '20px'}}>
            Go to homepage
          </button>
        </div>
  
      </>
  )
}

export default Success