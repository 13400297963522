import React, { useEffect, useState } from 'react'

const ToTop = () => {

    /**
 * header & go top btn active on page scroll
 */
const [showScrollBtn, setShowScrollBtn] = useState(false)

useEffect(()=> {
    window.addEventListener('scroll', listenToScroll);
    return ()=> window.removeEventListener("scroll", listenToScroll)

}, [])

const listenToScroll =()=> {
    let minHight = 80
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop

    if(winScroll >= minHight) {
        setShowScrollBtn(true)
    } else {setShowScrollBtn(false)}
}

// console.log(showScrollBtn)



// const header = document.querySelector("[data-header]");
// const goTopBtn = document.querySelector("[data-go-top]");

// window.addEventListener("scroll", ()=> {
//   if (window.scrollY >= 80) {
//     header.classList.add("active");
//     goTopBtn.classList.add("active");
//   } else {
//     header.classList.remove("active");
//     goTopBtn.classList.remove("active");
//   }
// });
  return (
    <>
    {showScrollBtn && <a href="#top" className="go-top-btn">
        <ion-icon name="arrow-up-outline"></ion-icon>
    </a>}
    </>
  )
}

export default ToTop