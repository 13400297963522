import React from 'react'

const Logo = () => {
  return (
    <div>
        <h2 className='logo-text'>
            LUJ
        </h2>
    </div>
  )
}

export default Logo