import React, { useEffect, useState } from 'react'
import Header from '../header/header'
import './singleProduct.css'
import { useDispatch, useSelector } from 'react-redux'
import { addProducts } from '../../redux/cartRedux'
import { publicRequest } from '../../requestMethods'
import { useLocation } from 'react-router-dom'
// import '../../index.css'

const SingleProduct = () => {


    const location = useLocation()
    const id = location.pathname.split("/")[2]
    const [product, setProduct] = useState({})
    const dispatch = useDispatch()
    const quantity = 1 

    useEffect(()=> {
        const getProduct = async () => {
            try {
                const res = await publicRequest.get(`/product/find/${id}`)
                
                setProduct(res.data)
            } catch(err) {
    
            }
        }
        
        getProduct()
    }, [id])

    

    const cartDetails = useSelector(state => state.cart)


    const handleClick =()=> {
        const targetId = id; // Replace with the ID you want to check
        let idExists = false;
        
        for (let i = 0; i < cartDetails.products.length; i++) {
          if (cartDetails.products[i]._id === targetId) {
            idExists = true;

           
            break;
          }
        }

        if (!idExists) {
            dispatch(
                addProducts({...product, quantity})
            )
        }
    }

    // console.log(product)

    // const amount = useSelector( state => state.cart.quantity)
  return (
    <div style={{}}>
        <Header  />
        <div className='' style={{backgroundColor: "whitesmoke", padding: "40px", display: "flex"}}>
            <div className='product-img'>
                <div className='product-img-wrapper'>
                    <img src={product.img} alt="" width={'100%'} height={"100%"} className="product-img-box" />
                </div>
            </div>
            <div className='product-desc'>
                <div className='product-title'>
                    <p style={{fontSize: "30px", fontWeight: "bold", display: "flex", alignItems: "center"}}>
                        {product.title} <span style={{fontWeight: "200", fontSize: "20px", marginLeft: "15px"}}> - {product.brand}</span>
                    </p>
                    <p className='' style={{fontWeight: "200", marginTop: "10px"}}>
                        {product.description}
                    </p>
                </div>
                <div>
                    <p style={{fontWeight: "100", fontSize: "30px"}}>
                       &#8358;{product.price}
                    </p>
                </div>
                <div>
                    {/* <div style={{backgroundColor: `gray`, height: "25px", width: "60px", borderRadius: "20px", marginBottom: "15px"}}>
                        
                    </div> */}
                    <p>
                        Size: {product.size}
                    </p>
                </div>
                <div  style={{display: "flex", justifyContent: "center"}}>
                    {/* <button onClick={handleClick} className='add-to-wishlist' style={{}}>
                        <span>
                            <ion-icon name="bag-outline"></ion-icon>
                        </span>
                        <span>
                            Add to cart
                        </span>
                    </button> */}
                    <button onClick={handleClick} className='add-to-cart' style={{}}>
                        <span>
                            <ion-icon name="bag-outline"></ion-icon>
                        </span>
                        <span>
                            Add to cart
                        </span>
                    </button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default SingleProduct