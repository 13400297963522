import React from 'react'
import Footer from '../components/footer/Footer'
import Header from '../components/header/header'
import Banner from '../components/banner/Banner'
import Collection from '../components/collection/Collection'
import Product from '../components/product/Product'
import Cta from '../components/cta/Cta'
import Special from '../components/special/Special'
import Service from '../components/service/Service'
import InstaPost from '../components/insta/InstaPost'
import ToTop from '../components/top/ToTop'

const Home = () => {


  return (
    <div className=''>
        <Header />
        <Banner />
        <Collection />
        <Product />
        <Cta />
        <Special />
        <Service />
        <InstaPost />
        <Footer />
        <ToTop />
    </div>
  )
}

export default Home