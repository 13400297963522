import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
    name: "cart",
    initialState: {
        products: [],
        quantity: 0,
        total: 0,
        showCart: false
    },
    reducers: {
        addProducts: (state, action) => {
            state.quantity += 1;
            state.products.push(action.payload)
            state.total += action.payload.price
        },
        removeProducts: (state, action) => {
            const updatedProducts = state.products.filter(item => item._id !== action.payload._id)

            return {
                ...state,
                products: updatedProducts
            }
            
        },

        toggleCart: (state, action) => {
            state.showCart = action.payload.showCart
        },

        resetCart: (state) => {
            state.products = []
        }
    }
})

export const {addProducts, toggleCart, removeProducts, resetCart} = cartSlice.actions

export default cartSlice.reducer